<template>
  <section>
    <v-alert
      v-if="!permissionCheck('view-organization-purchase-list') && !permissionCheck('read-group')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      v-if="permissionCheck('view-organization-purchase-list') || permissionCheck('read-group')"
      shaped
      color="#ECEFF1"
    >
      <br>
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        class="my-tree-view-style"
      >
        <span slot-scope="{node, path, tree}">
          <input
            id="myCheck"
            type="checkbox"
            :checked="node.$checked"
            @change="onCheckBoxChange(node, tree, path)"
          >
          {{ node.text }}
        </span>
      </base-tree-view-without-actions>
      <br>
      <br>
      <v-card-actions>
        <v-btn
          color="red darken-3"
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#FF3700"
          :disabled="btnDisable"
          @click="toFileUpload()"
        >
          Next
        </v-btn>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </section>
</template>
<script>
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import global from 'src/mixins/global';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'SelectDigiBoards',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
      };
    },
    computed: {
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      organizationDigiBoards () {
        return this.$store.getters['digiboards/getOrganizationDigiBoardTimeSlots'];
      },
      isBoardSelected () {
        if (this.selectedDigiBoards.length > 0) {
          return true;
        }
        return false;
      },
      isOrganizationDigiBoardsExist () {
        if (this.organizationDigiBoards.some(item => item.time_slots.length > 0)) {
          return true;
        }
        return false;
     },
     btnDisable () {
      if (this.isBoardSelected === true && this.isOrganizationDigiBoardsExist === true) {
          return false;
        }
        return true;
     },
    },
    async mounted () {
      this.loading = true;
      this.$store.dispatch('campaigns/clearCampaignFile');
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('view-organization-purchase-list') && await this.fetchOrganizationDigiBoardTimeSlots();
      this.showBoardTreeList();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchOrganizationDigiBoardTimeSlots () {
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchOrganizationDigiBoardTimeSlots');
        this.loading = false;
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.organizationDigiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.organizationDigiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },
      toFileUpload () {
        this.loading = true;
        const selectedOrgDigiBoardIds = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            selectedOrgDigiBoardIds.push(node.id);
          }
        });
        const selectedOrgDigiBoards = this.organizationDigiBoards.filter(item => selectedOrgDigiBoardIds.includes(item.digi_board.external_id));
        this.$store.dispatch('digiboards/addSelectedOrgBoardsForContentLinking', selectedOrgDigiBoards);
        setTimeout(() => {
          this.loading = false;
          this.$emit('second-step');
        }, 2000);
      },
      cancel () {
        this.$router.push({ name: 'Campaign List' });
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
input[type="checkbox"] {
accent-color: #FF3700
}
</style>
