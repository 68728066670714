<template>
  <section>
    <v-card
      shaped
      color="#ECEFF1"
    >
      <delete-campaign
        v-if="showDelete"
        :title="'Delete campaign file?'"
        :message="deleteMessage"
        @closed="showDelete = false"
        @confirmed="deleteConfirmed()"
      />
      <select-digi-board-time-slots
        v-if="showBoardTimeSlots"
        :content-details="contentToLink"
        :campaign-form-details="campaignFormDetails"
        @closed="showBoardTimeSlots = false"
      />
      <overlay-alert
        :show-progress="showProgress"
        :process-alert="processAlert"
      />
      <v-card-text>
        <p>
          <span class="font-my-style">
            Campaign Name: <font class="campaign-name"> {{ campaignFormDetails.campaignName }}</font>
          </span> <br>
          <span class="font-my-style">
            Campaign Start: <font class="campaign-dates"> {{ campaignFormDetails.start | convertToLocal }}</font>
          </span> <br>
          <span class="font-my-style">
            Campaign End: <font class="campaign-dates"> {{ campaignFormDetails.end | convertToLocal }}</font>
          </span> <br>
        </p>
        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="campaignFiles"
            class="elevation-1"
            mobile-breakpoint="100"
            disable-sort
          >
            <template
              v-slot:item="{ item, index }"
            >
              <tr>
                <td>
                  {{ item.formData.original_file_name }}
                </td>
                <td>{{ item.formData.size | sizeConverter }}</td>
                <td>{{ item.formData.dimension | dimensionFormatter }}</td>
                <td>
                  <div v-if="item.formData.content_type === 'video'">
                    <font>{{ item.formData.duration | durationConverter }}</font>
                  </div>
                  <div v-else>
                    <font>N/A</font>
                  </div>
                </td>
                <td>
                  <div v-if="isContentImgOrGif(item.formData.content_type)">
                    <v-img
                      width="75"
                      height="75"
                      contain
                      :src="getFilePath(item.formData.file_path)"
                      @click="popUpImage(item.formData.file_path)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF3700"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                  <div
                    v-else
                    class="video-div-style"
                  >
                    <video
                      width="75"
                      height="75"
                      controls
                      contatin
                    >
                      <source
                        :src="getFilePath(item.formData.file_path)"
                        type="video/mp4"
                      >
                    </video>
                  </div>
                </td>
                <td>
                  <v-btn
                    class="mt-n2"
                    elevation="1"
                    fab
                    color="#37474F"
                    x-small
                    icon
                    @click="assignDigiBoardTimeSlot(item)"
                  >
                    <v-icon>mdi-timer-edit-outline</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-col>
                    <v-btn
                      class="mt-n2"
                      elevation="1"
                      fab
                      x-small
                      icon
                      color="#C62828"
                      @click.stop
                      @click="deleteCampaignFile(index)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </td>
              </tr>
            </template>
          </v-data-table>
          <br>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="$router.push({ name: 'Campaign List' })"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              color="#FF3700"
              @click="createCampaign()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <image-preview
      v-if="showPreview"
      :url="previewUrl"
      @closed="showPreview = false"
    />
  </section>
</template>
<script>

  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import { cdnUrl } from 'src/app-globals';
  import ImagePreview from '../component/ImagePreview';
  import Constants from 'src/constants';
  import SelectDigiBoardTimeSlots from './SelectDigiBoardTimeSlots.vue';
  import moment from 'moment';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';

  export default {
    name: 'ReviewList',
    components: {
      'centre-spinner': spinner,
      'delete-campaign': DeleteDialog,
      'image-preview': ImagePreview,
      'select-digi-board-time-slots': SelectDigiBoardTimeSlots,
      'overlay-alert': OverlayAlert,
    },

    filters: {
      sizeConverter (val) {
        return val / 1000 + ' kb ';
      },
      durationConverter (val) {
        return val / 1000 + ' seconds';
      },
      dimensionFormatter (val) {
        if (val === null) {
          return 'Not available';
        }

        return `${val} px`;
      },
      convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMM YYYY hh:mm A');
    },
    },
    props: {
      campaignFormDetails: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        loading: false,
        showDelete: false,
        deleteIndex: '',
        deleteMessage: 'This action will permanently remove the item.',
        headers: [
          { text: 'File Name', value: 'original_file_name' },
          { text: 'Size', value: 'size' },
          { text: 'Dimension', value: 'dimension' },
          { text: 'Duration', value: 'duration' },
          { text: 'Content' },
          { text: 'Assign Slot', value: 'time_slot' },
          { text: 'Delete', value: 'delete' },
        ],
        showPreview: false,
        previewUrl: '',
        showBoardTimeSlots: false,
        contentToLink: {},
        showProgress: false,
        processAlert: '',
      };
    },
    computed: {
      campaignFiles () {
        return this.$store.getters['campaigns/getCampaignFiles'];
      },
      campaignName () {
        return this.$store.getters['campaigns/getCampaignName'];
      },
      bannerFiles () {
        return this.$store.getters['campaigns/getBannerFiles'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      boardTimeSlotsForLinking () {
        return this.$store.getters['digiboards/getSelectedOrgBoardsForContentLinking'];
      },
    },
    methods: {
      deleteCampaignFile (index) {
        if (this.campaignFiles.length === 1) {
          this.deleteMessage = 'This is the last content to run the campaign. Deleting will cancel your campaign creation.';
        }
        this.showDelete = true;
        this.deleteIndex = index;
      },
      async deleteConfirmed () {
        await this.$store.dispatch('campaigns/deleteCampaignFile', {
          index: this.deleteIndex,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Campaign file deleted',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          if (this.campaignFiles.length === 0) {
            this.$router.push({ name: 'Campaign List' });
          }
        }).finally(
          this.showDelete = false,
        );
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      popUpImage (fileName) {
        this.previewUrl = this.getFilePath(fileName);
        this.showPreview = true;
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
      assignDigiBoardTimeSlot (contentDetails) {
        this.contentToLink = contentDetails;
        this.showBoardTimeSlots = true;
      },
      convertToGmt (date) {
        return moment(date).utc();
      },
      async createCampaign () {
        for (const item of this.campaignFiles) {
          if (item.digiBoardTimeSlots.length === 0) {
            this.$store.dispatch('alert/onAlert', {
              message: 'Device slots not assigned, please choose at least one slot for all your campaign contents.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
        }
        this.showProgress = true;
        this.processAlert = 'Creating...';

        const schedules = [];
        for (const advancedSchedule of this.advancedScheduleData) {
          schedules.push(advancedSchedule.id);
        }
        const uniqueFileName = [];
        for (const campaignFile of this.campaignFiles) {
          uniqueFileName.push(campaignFile.formData.unique_file_name);
        }
        for (var i = 0; i < this.campaignFiles.length; i++) {
          const digiBoardTimeSlotIds = this.campaignFiles[i].digiBoardTimeSlots.map(item => item.boardTimeSlotId);
          await this.$store.dispatch('campaigns/createBannerFiles', {
            unique_file_name: uniqueFileName[i],
            board_listed: JSON.stringify(false),
            name: this.campaignFormDetails.campaignName,
            digi_board_time_slots: digiBoardTimeSlotIds,
            start: this.convertToGmt(this.campaignFormDetails.start),
            end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
            schedules: schedules,
          });
        }
        this.processAlert = 'Linking...';
        const banners = [];
        for (const banner of this.bannerFiles) {
          banners.push(banner.id);
        }
        const digiBoardIds = [];
        for (const item of this.boardTimeSlotsForLinking) {
          digiBoardIds.push(item.digi_board.external_id);
        }
        await this.$store.dispatch('campaigns/createCampaign', {
          name: this.campaignFormDetails.campaignName,
          comment: this.campaignFormDetails.comment,
          start: this.convertToGmt(this.campaignFormDetails.start),
          end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
          global: this.campaignFormDetails.global,
          schedules: schedules,
          banners: banners,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Campaign created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$store.dispatch('publish/addSelectedBoardsForPublish', digiBoardIds);
          this.$store.dispatch('campaigns/clearCampaignFile');
          this.$store.dispatch('campaigns/clearCampaignName');
          this.$store.dispatch('schedule/clearAdvancedSchedule');
          this.$store.dispatch('campaigns/clearBannerFile');
          this.showProgress = false;
          this.processAlert = '';
          this.$router.push({ name: 'Campaign List' });
        }).catch(() => {
          this.$router.push({ name: 'Campaign List' });
        });
      },
    },
  };
</script>

<style scoped>
.sliderContainer {
  width: 150px !important;
  margin: 10px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #CFD8DC
}
.v-data-table {
  background-color: #ECEFF1;
}
.font-my-style {
  font-weight: bold;
  color: #37474F;
  line-height: 2;
  font-family: 'Times New Roman', Times, serif;
  margin-left: 10px;
}
.my-p-style {
  margin-bottom: 0px;
  margin-top: -25px;
  margin-left: 4px;
}
.video-div-style {
  margin: 10px;
  margin-left: -1px;
}
.campaign-name {
  color: #FF3700;
}
.campaign-dates {
  color: #546E7A;
}
</style>
