<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-calendar-clock
      </v-icon> Campaign Creation
    </h2>
    <br>
    <v-alert
      v-if="!isOrganizationDigiBoardsTimeSlotsExist"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      No device slots found against your organization, please do a purchase before proceeding.
    </v-alert>
    <v-stepper
      v-model="createCampaign"
      class="my-stepper-style"
      shaped
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="createCampaign > 1"
          step="1"
          color="#FF3700"
        >
          Device Selection
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="createCampaign > 2"
          step="2"
          color="#FF3700"
        >
          File Upload
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="createCampaign > 3"
          color="#FF3700"
          step="3"
        >
          Schedule Contents
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          color="#FF3700"
          step="4"
        >
          Review & Assign Slot
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <select-digi-boards
            @second-step="firstStepChange()"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <file-upload
            @third-step="secondStepChange()"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card
            shaped
            color="#ECEFF1"
          >
            <v-card-text>
              <v-form>
                <v-container fluid>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="campaignName.name"
                        label="Campaign Name*"
                        hint="Name for your reference - 'MarketingCampaign'(Only alpha dashes allowed)."
                        :rules="[(v) => !!v || 'This field is required']"
                        type="text"
                        @keydown.space.prevent
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-textarea
                        v-model="comment"
                        label="Campaign-Comment"
                        hint="Your reference - 'Run weekly across the company'."
                        outlined
                        rows="3"
                        row-height="25"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="start date"
                      >
                        <v-datetime-picker
                          v-model="start"
                          label="Dispalyed From*"
                          date-format="yyyy-MM-dd"
                          time-format="HH:mm:ss"
                          :text-field-props="{
                            suffix: userTimeZone,
                            errorMessages: errors,
                            class: 'custom-label-color',
                          }"
                          :date-picker-props="{
                            headerColor: '#37474F',
                            min: minStartDate
                          }"
                          :time-picker-props="{
                            headerColor: '#37474F'
                          }"
                        >
                          <template v-slot:dateIcon>
                            <v-icon
                              large
                              color="#37474F"
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon
                              color="#37474F"
                              large
                            >
                              mdi-alarm
                            </v-icon>
                          </template>
                        </v-datetime-picker>
                      </validation-provider>
                    </v-col>
                    <v-col>
                      <v-datetime-picker
                        v-model="end"
                        label="Displayed To*"
                        :text-field-props="{
                          hint: 'Leave blank if never ends',
                          persistentHint: true,
                          suffix: userTimeZone
                        }"
                        :date-picker-props="{
                          headerColor: '#37474F',
                          min: minEndDate
                        }"
                        :time-picker-props="{
                          headerColor: '#37474F'
                        }"
                      >
                        <template v-slot:dateIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                        <template v-slot:timeIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-alarm
                          </v-icon>
                        </template>
                      </v-datetime-picker>
                    </v-col>
                  </v-row>
                  <v-col
                    cols="12"
                    class="text-left"
                  >
                    <v-btn
                      v-if="showScheduleBtn"
                      small
                      dark
                      class="mt-2"
                      color="#FF3700"
                      depressed
                      default
                      rounded
                      @click="showAdvancedSchedule = true"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                      Advanced Schedule
                    </v-btn>
                    <advanced-scheduling v-model="showAdvancedSchedule" />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <advanced-scheduling-list @show-content-form="showContentForm" />
                    <advanced-schedule-edit-before-creation
                      v-if="showScheduleContent"
                      :editvalues="editScheduleContent"
                      :scheduleindex="scheduleIndex"
                      @closed="showScheduleContent = false"
                    />
                  </v-col>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-switch
                        v-model="global"
                        label="Global Campaign"
                        hint="Organizational level campaign."
                        :persistent-hint="true"
                        color="#FF3700"
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <br>
                <br>
                <p class="text-sm-left">
                  *indicates required field
                </p>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mt-2"
                color="red darken-3"
                @click="$router.push({ name: 'Campaign List' })"
              >
                Cancel
              </v-btn>
              <v-spacer />
              <v-btn
                color="#FF3700"
                @click="create()"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <review-list :campaign-form-details="campaignFormDetails" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <br>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import moment from 'moment-timezone';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';
  import AdvancedScheduling from '../component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from '../component/schedules/AdvancedSchedulingList.vue';
  import FileUpload from './FileUpload.vue';
  import ReviewList from './ReviewList.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import SelectDigiBoards from './SelectDigiBoards.vue';
  import AdvancedScheduleEditBeforeCreation from '../component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'CreateCampaigns',
    components: {
      AdvancedScheduling,
      AdvancedSchedulingList,
      FileUpload,
      ReviewList,
      'centre-spinner': spinner,
      SelectDigiBoards,
      AdvancedScheduleEditBeforeCreation,
    },
    mixins: [global],
    data () {
      return {
        createCampaign: 1,
        showAdvancedSchedule: false,
        comment: null,
        global: false,
        start: '',
        end: '',
        minStartDate: moment().format('YYYY-MM-DD'),
        helper: new ModuleHelper(),
        loading: false,
        showScheduleContent: false,
        scheduleIndex: 0,
        editScheduleContent: {},
        campaignFormDetails: {
          campaignName: '',
          comment: null,
          start: '',
          end: '',
          global: false,
        },
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.permissionCheck('create-campaign') === false || vm.isPlanExpired === true || vm.helper.isModuleExist(Constants.MODULE_CAMPAIGN) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
      showScheduleBtn () {
        return (this.helper.isSubModuleExist(Constants.MODULE_CAMPAIGN, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
      campaignName () {
        return this.$store.getters['campaigns/getCampaignName'];
      },
      userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
      organizationDigiBoards () {
        return this.$store.getters['digiboards/getOrganizationDigiBoardTimeSlots'];
      },
      isOrganizationDigiBoardsTimeSlotsExist () {
        if (this.organizationDigiBoards.some(item => item.time_slots.length > 0)) {
          return true;
        }
        return false;
     },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    mounted () {
      this.$store.dispatch('campaigns/clearSlotUpdatedCampaignFiles');
    },
    methods: {
      firstStepChange () {
        this.createCampaign = 2;
      },
      secondStepChange () {
        this.createCampaign = 3;
      },
      async create () {
        if (this.start === null || this.start === '' || this.campaignName.name === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.campaignFormDetails.campaignName = this.campaignName.name;
        this.campaignFormDetails.comment = this.comment;
        this.campaignFormDetails.start = this.start;
        this.campaignFormDetails.end = this.end;
        this.campaignFormDetails.global = this.global;
        setTimeout(() => {
          this.loading = false;
          this.createCampaign = 4;
        }, 2000);
      },
      showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
    },
  };
</script>
<style scoped>
.my-stepper-style {
  background-color: #CFD8DC !important;
  margin-right: 30px;
  margin-left: 30px;
}
.title-style {
  color: #37474F;
  margin-left: 30px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  margin-left: 30px;
  margin-right: 30px;
}
</style>
